import React, { useState } from 'react'
import { WorkplaceWellness } from './components/wellness-components'
import { LatLng } from 'leaflet'
import { useQuery } from '@apollo/client'
import { BuildingDetails } from './components/WellnessComponents/BuildingDetails/BuildingDetailsTypes'
import { PodDetails } from './components/WellnessComponents/PodDetails/PodDetailsTypes'
import {
  WellnessDisplaysQuery,
  WellnessDisplaysQueryVariables,
  WellnessDisplaysDocument,
  WellnessAddressesQuery,
  WellnessAddressesQueryVariables,
  WellnessAddressesDocument
} from './graphql/__generated__/types'
import useDeepEffect from 'use-deep-compare-effect'

function WellnessApp() {
  const [allPods, setAllPods] = useState<PodDetails[] | undefined>(undefined)
  const [allBuildings, setALLBuildings] = useState<BuildingDetails[] | undefined>(undefined)

  const { data: DataPods, loading: loadingPods } = useQuery<WellnessDisplaysQuery, WellnessDisplaysQueryVariables
  >(WellnessDisplaysDocument, { fetchPolicy: 'cache-and-network', pollInterval: 10000 })

  useDeepEffect(() => {
    if (DataPods?.wellnessDisplays) {
      const list = DataPods?.wellnessDisplays.map(el => (
        {
          id: el?.id,
          podName: el?.name,
          availailtiy: el?.available,
          trafficScore: el?.trafficScore,
          booked: el?.booked,
          address_id: el?.address_id,
          wellnessScore: el?.wellnessScore,
          lat: el?.lat,
          lng: el?.lng,
          floor: el?.floor
        } as PodDetails))

      setAllPods(list)
    }
  }, [DataPods || []])

  const { data: DataBuildings, loading: loadingBuildings } = useQuery<WellnessAddressesQuery, WellnessAddressesQueryVariables
  >(WellnessAddressesDocument, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000
  })

  useDeepEffect(() => {
    if (DataBuildings?.wellnessAddresses) {
      const list = DataBuildings?.wellnessAddresses//.filter(el => el?.num_of_displays && el?.num_of_displays > 0)
        .map(el => (
          {
            buildingName: el?.building,
            buildingNickname: el?.nickname ?? undefined,
            percentageBooked: el?.percent_booked,
            wellnessScore: el?.wellnessScore,
            address_full: el?.address_full,
            id: el?.id,
            lat: el?.lat,
            lng: el?.lng,
            totalDisplays: el?.num_of_displays,
            trafficScore: el?.trafficScore
          } as BuildingDetails))

      setALLBuildings(list)
    }

  }, [DataBuildings || []])

  const urlParams = new URLSearchParams(window.location.search)
  const lat = urlParams.get('lat')
  const long = urlParams.get('long')
  const userLocation = new LatLng(lat, long)

  return (
    <div >
      {!loadingPods && !loadingBuildings &&
        <WorkplaceWellness allPods={allPods} allBuildings={allBuildings} isAdmin={false} userLoc={userLocation} />
      }
    </div>
  )
}

export default WellnessApp
