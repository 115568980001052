import React, { useState } from 'react'
import { FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core'
import styles from './FloorDropdown.module.scss'
import { BuildingDetails } from '../BuildingDetails/BuildingDetailsTypes'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { PodDetails } from '../PodDetails/PodDetailsTypes'
import { useEffect } from 'react'

type Props = {
  floor: number
  setFloor: (number) => void
  buildingsData: BuildingDetails[] | undefined
  allPods: PodDetails[] | undefined
  zoomToBuildings: () => void
}

function FloorDropdown(props: Props) {

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.setFloor(event.target.value as number)
  }

  const [addressFloorsMap, setAddressFloorsMap] = useState<{ number: string[] }>()

  useEffect(() => {
    if (props.allPods) {
      const map: any = {}

      props.allPods.forEach((el) => {
        const addressID = el.address_id
        const floor = el?.floor?.toString() || null

        if (addressID in map && floor) {
          if (!map[addressID].includes(floor)) {
            map[addressID] = [...map[addressID], floor]
          }
        } else {
          map[addressID] = [floor, 0]
        }
      })
      if (Object.keys(map).length > 0) {
        setAddressFloorsMap(map)
      }
    }

  }, [props.allPods])

  return (
    <>
      { props.buildingsData && props.buildingsData?.length === 1 &&
        <div className={styles.topCard}>
          <Button onClick={props.zoomToBuildings} className={styles.icon}>
            <ArrowBackIosIcon />
          </Button>

          <div className={styles.name}>
            <span> {props.buildingsData[0].buildingNickname}</span>
          </div>
          <FormControl variant="standard" className={styles.dropdown} size='medium'>
            <InputLabel variant='standard'>Floor</InputLabel>
            <Select value={props.floor} onChange={handleChange}>
              {addressFloorsMap &&
                addressFloorsMap[props.buildingsData[0].id].sort().map(floor => (
                  <MenuItem value={floor}>{floor === 0 ? 'all' : floor}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      }
    </>
  )
}

export default FloorDropdown