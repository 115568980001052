import React , { useEffect , useState} from 'react'
import styles from './SlidingCards.module.scss'
import ListIcon from '@material-ui/icons/List'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import BuildingsDetails from '../WellnessComponents/BuildingDetails/BuildingDetails'
import { BuildingDetails } from '../WellnessComponents/BuildingDetails/BuildingDetailsTypes'
import {  PodDetails } from '../WellnessComponents/PodDetails/PodDetailsTypes'
import PodsDetails  from '../WellnessComponents/PodDetails/PodDetails'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { LatLng } from 'leaflet'

type Props = {
    cardID: string
    mapType: 'Buildings' | 'Pods'
    setDrawerOpen: (boolean) => void
    setCardID: (n:string) => void
    podsData: PodDetails[] | undefined
    buildingsData: BuildingDetails[] | undefined
    isAdmin: boolean
    userLocation: LatLng | undefined
    setZoomToUserLoc: (boolean) => void
    zoomToUserLoc: boolean
}

function  SlidingCards(props: Props) { 
    const [swipe, setSwipe ]=useState<any>(null)
    
    useEffect(() => {
        if (swipe) {
            const IDs = swipe.slides.map(el => el.id)
            swipe.slideTo(IDs?.indexOf(props.cardID))
        }
       
    }, [props.cardID])
  
    const pods =  props.podsData?.map(pod =>(
        <SwiperSlide id={pod.id} className={styles.slidingDiv}  key={pod.id}>
                        <PodsDetails data={pod} isAdmin={props.isAdmin} />
        </SwiperSlide>
    ))    

    const buildings =  props.buildingsData?.map((building)=> (
        <SwiperSlide id={building.id.toString()} className={styles.slidingDiv} key={building.id.toString()}  >
            <BuildingsDetails data={building} userLocation={props.userLocation}/>
        </SwiperSlide>
    ))  
    
    return (
        <div className={styles.sliding}>
            {props.userLocation && 
                <button className={styles.gpsButton} onClick={() => props.setZoomToUserLoc(!props.zoomToUserLoc)} >
                    <GpsFixedIcon className={styles.gpsIcon}  />
                </button>
            }
            {!props.isAdmin && 
                <button className={styles.listButton} onClick={() => props.setDrawerOpen(true)} >
                        <ListIcon className={styles.listButtonIcon}  />
                </button>
            }
            <Swiper 
                className={'swiper-container'}
                slidesPerView={props.isAdmin ? 2.5 : 1.25}
                spaceBetween={10}
                onSlideChange={(swiper) => props.setCardID(swiper.slides[swiper.realIndex].id)  }
                onInit={(swiper) => setSwipe( swiper) }
            >
                {(props.mapType ==='Pods' && props.podsData ) &&  
                    pods
                }
                {(props.mapType ==='Buildings' && props.buildingsData ) &&   
                    buildings         
                }  
            </Swiper>        
        </div> 
    )
}
export default SlidingCards 



