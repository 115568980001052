import React, { useState , useEffect } from 'react'
import FullPageMap from './Map/FullPageMap'
import  MapElements  from './MapElements/MapElements'
import  SlidingCards  from './SlidingCards/SlidingCards'
import  ListView  from './ListView/ListView'
import { LatLng , LatLngBounds } from 'leaflet'
import { PodDetails } from './WellnessComponents/PodDetails/PodDetailsTypes'
import { BuildingDetails } from './WellnessComponents/BuildingDetails/BuildingDetailsTypes'
import styles from './index.module.scss'
import FloorDropdown from './WellnessComponents/FloorDropdown/FloorDropdown'

type Props = {
  allPods: PodDetails[] | undefined
  allBuildings: BuildingDetails[] | undefined
  isAdmin: boolean
  userLoc: LatLng
}
export function WorkplaceWellness (
  {allBuildings,
  allPods,
  isAdmin,
  userLoc
  }:Props)  {
  
    const [zoom, setZoom]= useState(isAdmin ? 8 : 13)
    const [floor, setFloor] = useState(0)
    const [mapBounds , setMapBounds] = useState<LatLngBounds| undefined>(undefined)
    const [drawerOpen, setDrawerOpen]= useState(false)
    const [zoomToUserLoc, setzoomToUserLoc] = useState(true)
    const [cardID , setCardID] =useState<string>('')
    
    const [pods, setPods]=useState<PodDetails[] | undefined>(undefined)
    const [buildings, setBuildings]=useState<BuildingDetails[] | undefined>(undefined)
   
    const [userLocation,  setUserLocation] = useState<LatLng | undefined >(userLoc)

    useEffect(() => {
      setInterval(()=> { 
        updateUserLocation(userLocation) 
      }, 3000)
    }, [])

    const updateUserLocation = (current: LatLng) =>{
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => { 
            const newLoc = new LatLng(position.coords.latitude, position.coords.longitude)  
            const distance = current.distanceTo(newLoc)
            if ( distance > 5 ) {
              setUserLocation(newLoc)  
            }        
          },
          err => {
            if( err.PERMISSION_DENIED){
              setUserLocation(undefined)
            }
          }
        
        )
      }
    }

    useEffect(() => {
          let  podsShown = allPods?.filter(el => mapBounds?.contains(new LatLng(el.lat, el.lng)))
          
          if (floor != 0 ){
            podsShown = allPods?.filter(el => (mapBounds?.contains(new LatLng(el.lat, el.lng)) && el.floor == floor))
          }
          
          setPods( podsShown )
          const addressesInView = podsShown?.map(el => el.address_id)
          if (addressesInView ) {
              setBuildings(allBuildings?.filter(el =>  addressesInView.includes(el.id) ) )
          }
          
          if (zoom < 17 ){
            setFloor(0)
          }
    }, [mapBounds , floor])
    
    const mapType = (zoom < 17 ? 'Buildings' : 'Pods')

  return (
      <div className={isAdmin ? styles.admin :styles.outside}> 
       {mapType === 'Pods' &&
                <FloorDropdown
                    floor={floor} 
                    setFloor={setFloor}
                    buildingsData={buildings}
                    allPods={allPods}
                    zoomToBuildings={()=>setZoom(15)}
                />
        }
        <FullPageMap userLocation={userLocation} isAdmin={isAdmin} zoom={zoom} >
              {!isAdmin &&
                <ListView 
                  mapType={mapType}
                  zoom={zoom}
                  isOpen={drawerOpen} 
                  closeDrawer={() => setDrawerOpen(false)}
                  setMapView={setMapBounds}
                  allPods={allPods}
                  allBuildings={allBuildings}
                />
              }
              <MapElements
                setZoom={setZoom}
                setCardID={setCardID}
                setMapView={setMapBounds}
                zoom={zoom}
                mapType={mapType}
                mapView={mapBounds}
                cardID={cardID}
                floor={floor} 
                setFloor={setFloor}
                buildingsData={buildings}
                allPods={allPods}
                userLocation={userLocation}
                zoomToUserLoc={zoomToUserLoc}
              />
             
        </FullPageMap>
            <SlidingCards 
              setDrawerOpen={setDrawerOpen}
              cardID={cardID}
              mapType={mapType}
              setCardID={setCardID}
              podsData={pods}
              buildingsData={buildings}
              isAdmin={isAdmin}
              userLocation={userLocation}
              setZoomToUserLoc={setzoomToUserLoc}
              zoomToUserLoc={zoomToUserLoc}
            /> 
      </div>
  )
}

export default WorkplaceWellness
