import React, { useEffect, useState } from 'react'
import {divIcon , LatLng } from 'leaflet'
import { Marker } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import RoomIcon from '@material-ui/icons/Room'
import { renderToString } from 'react-dom/server'
import './clustering.css'
import styles from './Clusters.module.scss'
import { PodDetails } from '../../WellnessComponents/PodDetails/PodDetailsTypes'

export interface MarkerObject {
  latlng: LatLng
  name: string
  id: string
  wellnessScore?: number
}

type Props = {
  mapType: 'Buildings' | 'Pods'
  zoom: number
  setCardID: (e:string) => void
  cardID: string
  allPods:PodDetails[]  | undefined
  floor: number
}

function Clusters(props: Props) {
   const [podsData, setPodsData] = useState<MarkerObject[]>([])
   const [addressPodsMap, setAddressPodsMap] = useState<{number : MarkerObject[]} | {}>({})

    useEffect(() => {
          const pods:MarkerObject[] =[]
          const addressPodsMap: {  number : MarkerObject[]} | {} = {}
        
          if (props.allPods){
            props.allPods.forEach(el =>{
                const addressID = el.address_id
                const newPlace = { 
                    id: el.id , 
                    name: el.podName ,
                    latlng: new LatLng(el.lat, el.lng) , 
                    wellnessScore: Math.random()
                }

                const numPodsOnFloor = props.allPods?.filter(el =>  el.floor == props.floor).length ?? 0

                if (props.floor === 0 || (props.floor == el.floor && numPodsOnFloor > 0 ) ){
                  pods.push(newPlace)
                  if ( addressID in addressPodsMap ) {
                        addressPodsMap[addressID] = [... addressPodsMap[addressID] ,newPlace ]
                  }else {
                      addressPodsMap[addressID] = [newPlace ]
                  }
                }
             })
            setPodsData(pods)
            setAddressPodsMap(addressPodsMap)
        }
    }, [props.allPods , props.floor])

    const ConditionalWrap = ({ condition, wrap, children }) => (
        condition ? wrap (children) : children
      )

    const getClusters = (podList: MarkerObject[]) => {
      // Here you can see the ID's of layers are getting changed every render   - meaning that markers are re-renders every map move
      if ( podList && podList.length > 0) {
          return (
                    <ConditionalWrap 
                          condition={props.mapType === 'Buildings'}
                          wrap={children => (<MarkerClusterGroup> {children} </MarkerClusterGroup>  )}
                    >
                        {podList.map((place) => (
                              getMarker(place)
                        ))}
                    </ConditionalWrap> 
                  )
      }
      else{
        return 0
      } 
    }

    const getMarker = (place: MarkerObject ) => (
            <Marker 
                key={place.id}
                title={place.name}
                icon={place?.wellnessScore ? 
                  divIcon({
                    html: renderToString( 
                            <div  className={place?.wellnessScore > .66 ? styles.red : (place?.wellnessScore > .33 ? styles.yellow : styles.green)}>
                                <RoomIcon className={props.cardID === place.id ? styles.largeIcon : styles.icon} />
                                {props.cardID === place.id &&
                                      <div className={styles.name}>{place.name}</div>
                                }
                            </div>
                          )
                  }) :
                  divIcon({   
                          html: renderToString(   <RoomIcon onClick={()=> {}} className={props.cardID === place.id  ? styles.largeIcon : styles.icon} /> )
                          })
                }
                position={place.latlng} 
                onClick={()=>props.setCardID(place.id )}
            > 
              {props.cardID === place.id &&
                <div className={styles.name}>{place.name}</div>
              }
            </Marker>
    )

  return (
      <div>
        { props.zoom <= 11 ?
          (
            <MarkerClusterGroup> 
                  {podsData.map(place => (
                              getMarker(place)
                  ))}
            </MarkerClusterGroup> 
          )
        :
          ( <>
              {Object.values(addressPodsMap).map(list => (
                    getClusters(list)
              ))}
            </>
          )
        }
      </div>
  )
}

export default Clusters
